{
  "name": "parcel",
  "version": "1.0.0",
  "source": "src/index.html",
  "author": "syed <syednaeem15191@gmail.com>",
  "license": "MIT",
  "scripts": {
    "serve": "parcel --port=5000",
    "build": "parcel build && cp robots.txt dist/robots.txt",
    "start": "yarn && yarn serve"
  },
  "devDependencies": {
    "@parcel/transformer-sass": "^2.0.1",
    "parcel": "^2.0.1"
  },
  "dependencies": {}
}
