require('./../assets/vendor/rollbar/rollbar.min');
require('./../assets/vendor/bootstrap/js/bootstrap.bundle.min');
require('./../assets/vendor/fontawesome/js/fontawesome.min');
require('./../assets/vendor/waypoints/noframework.waypoints');
window.AOS = require('./../assets/vendor/aos/aos');
window.GLightbox = require('./../assets/vendor/glightbox/js/glightbox.min');
window.Swiper = require('./../assets/vendor/swiper/swiper-bundle.min');
window.Typed = require('./../assets/vendor/typed.js/typed.min');
window.axios = require('./../assets/vendor/axios/axios.min.js');
window.moment = require('./../assets/vendor/momentjs/moment.min');
window.Vue = require('./../assets/vendor/vue/vue.min.js');

require('./../assets/js/main');

window.axiosInstance = axios.create({
    baseURL: process.env.API_ENDPOINT,
    headers: {'X-api-key': process.env.API_KEY, locale: 'en'}
});

let profile = new Vue({
    el: '#profile',
    data() {
        return {
            name: null,
            social_links: [],
            profile_picture: null
        }
    }
});

let hero = new Vue({
    el: '#hero',
    data() {
        return {
            name: null,
            cover_photo: null
        }
    },
    mounted() {
        const typed = document.querySelector('.typed');
        if (typed) {
            let typed_strings = typed.getAttribute('data-typed-items');
            typed_strings = typed_strings.split(',');
            new Typed('.typed', {
                strings: typed_strings,
                loop: true,
                typeSpeed: 100,
                backSpeed: 50,
                backDelay: 2000
            });
        }
    }
});

let about = new Vue({
    el: '#about',
    data() {
        return {
            title: null,
            dob: null,
            phone: null,
            email: null,
            location: null,
            about: null,
            profile_picture: null
        }
    },
    computed: {
        dateOfBirth() {
            if (this.dob)
                return moment(this.dob).format('MMMM Do');
            return null;
        },
        age() {
            if (this.dob)
                return moment().diff(this.dob, 'years');
            return null;
        }
    }
});

let skills = new Vue({
    el: '#skills',
    data() {
        return {
            skills: []
        }
    },
    watch: {
        skills() {
            this.$nextTick(function () {
                let skilsContent = document.querySelector('.skills-content');
                if (skilsContent) {
                    new Waypoint({
                        element: skilsContent,
                        offset: '80%',
                        handler: function (direction) {
                            let progress = document.querySelectorAll('.progress .progress-bar');
                            progress.forEach((el) => {
                                el.style.width = el.getAttribute('aria-valuenow') + '%'
                            });
                        }
                    })
                }
            });
        }
    }
});

let resume = new Vue({
    el: '#resume',
    data() {
        return {
            summary: {
                name: null,
                about: null,
                location: null,
                phone: null,
                email: null,
            },
            educations: [],
            experiences: [],
            styles: {
                box_height: '900px',
                show_more_visibility: true
            }
        }
    },
    methods: {
        getFromToYear(dateStampFrom, dateStampTo) {
            dateStampFrom = this.year(dateStampFrom);
            dateStampTo = this.year(dateStampTo);
            return [dateStampFrom, dateStampTo ? dateStampTo : 'Now'].filter(i => i).join(' - ')
        },
        getFromToYearAndMonth(dateStampFrom, dateStampTo) {
            dateStampFrom = this.yearAndMonth(dateStampFrom);
            dateStampTo = this.yearAndMonth(dateStampTo);
            return [dateStampFrom, dateStampTo ? dateStampTo : 'Now'].filter(i => i).join(' - ')
        },
        year(dateStamp) {
            return dateStamp ? moment(dateStamp).format('YYYY') : '';
        },
        yearAndMonth(dateStamp) {
            return dateStamp ? moment(dateStamp).format('MMMM YYYY') : '';
        },
        showMore() {
            this.styles.box_height = `${this.$refs.resume_container.clientHeight + 120}px`;
            this.styles.show_more_visibility = false;
        }
    }
});

let testimonials = new Vue({
    el: '#testimonials',
    data() {
        return {
            testimonials: []
        }
    },
    watch: {
        testimonials() {
            this.$nextTick(function () {
                new Swiper('.testimonials-slider', {
                    speed: 600,
                    loop: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false
                    },
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },

                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        }
                    }
                });
            });
        }
    },
    methods: {
        timeFromNow(dateStamp) {
            return dateStamp ? moment(dateStamp).fromNow() : '';
        }
    }
});

let portfolio = new Vue({
    el: '#portfolio',
    data() {
        return {
            portfolio: [],
            styles: {
                box_height: '900px',
                show_more_visibility: true
            }
        }
    },
    watch: {
        portfolio() {
            this.$nextTick(function () {
                GLightbox({
                    selector: '.portfolio-lightbox'
                });
            });
        }
    },
    methods: {
        showMore() {
            this.styles.box_height = `${this.$refs.portfolio_container.clientHeight + 120}px`;
            this.styles.show_more_visibility = false;
        }
    }
});

let services = new Vue({
    el: '#services',
    data() {
        return {
            services: []
        }
    }
});

let contact = new Vue({
    el: '#contact',
    data() {
        return {
            location: null,
            key: process.env.GOOGLE_MAPS_API_KEY,
            uuid: null,
            email: null,
            phone: null,
            form: {
                contact: {
                    name: null,
                    email: null,
                    subject: null,
                    text: null
                },
                testimonial: {
                    name: null,
                    email: null,
                    title: null,
                    text: null
                }
            }
        }
    },
    mounted() {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.RECAPTCHA_SITE_KEY;
        document.head.append(script);
    },
    methods: {
        submitTestimonialForm() {
            let $this = this;
            grecaptcha.ready(function () {
                grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function (recaptcha_site_token) {
                    $this.$refs.testimonialLoading.style.display = 'block';
                    $this.$refs.testimonialError.style.display = 'none';
                    $this.$refs.testimonialSuccess.style.display = 'none';
                    let data = $this.form.testimonial;
                    data.profile_uuid = $this.uuid;
                    data.recaptcha_site_token = recaptcha_site_token;
                    axiosInstance.post('api/testimonial', data).then(response => {
                        if (response.data.success) {
                            $this.form.testimonial.name = null;
                            $this.form.testimonial.title = null;
                            $this.form.testimonial.email = null;
                            $this.form.testimonial.text = null;
                            $this.$refs.testimonialSuccess.style.display = 'block';
                            setTimeout(() => {
                                $this.$refs.testimonialSuccess.style.display = 'none'
                            }, 5000);
                        } else {
                            $this.$refs.testimonialError.innerHTML = 'error';
                            $this.$refs.testimonialError.style.display = 'block';
                        }
                    }).catch(ex => {
                        $this.$refs.testimonialError.style.display = 'block';
                        let error = '';
                        if (ex.response.status === 422)
                            Object.keys(ex.response.data).forEach(function (key) {
                                error += `<li>${ex.response.data[key][0]}</li>`;
                            });
                        else
                            error = ex.response.status + ': ' + ex.response.statusText;
                        $this.$refs.testimonialError.innerHTML = error;
                    }).then(() => {
                        $this.$refs.testimonialLoading.style.display = 'none';
                    });
                });
            });
        },
        submitContactForm() {
            let $this = this;
            grecaptcha.ready(function () {
                grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function (recaptcha_site_token) {
                    $this.$refs.contactLoading.style.display = 'block';
                    $this.$refs.contactError.style.display = 'none';
                    $this.$refs.contactSuccess.style.display = 'none';
                    let data = $this.form.contact;
                    data.profile_uuid = $this.uuid;
                    data.recaptcha_site_token = recaptcha_site_token;
                    axiosInstance.post('api/contact-us', data).then(response => {
                        if (response.data.success) {
                            $this.form.contact.name = null;
                            $this.form.contact.email = null;
                            $this.form.contact.subject = null;
                            $this.form.contact.text = null;
                            $this.$refs.contactSuccess.style.display = 'block';
                            setTimeout(() => {
                                $this.$refs.contactSuccess.style.display = 'none'
                            }, 5000);
                        } else {
                            $this.$refs.contactError.innerHTML = 'error';
                            $this.$refs.contactError.style.display = 'block';
                        }
                    }).catch(ex => {
                        $this.$refs.contactError.style.display = 'block';
                        $this.$refs.contactError.innerHTML = ex.response.status + ': ' + ex.response.statusText;
                    }).then(() => {
                        $this.$refs.contactLoading.style.display = 'none';
                    });
                });
            });
        }
    }
});

new Vue({
    el: '#vue_loader',
    mounted() {
        axiosInstance.get(`api/profiles/${process.env.PROFILE_UUID}`, {
            params: {
                relations: 'portfolios,social_links,experiences,services,skills,education,testimonials'
            }
        }).then(response => {
            if (response.data.profile_picture) {
                this.$nextTick(() => {
                    profile.$refs.headerProfileImage.style.backgroundImage = `url('${process.env.API_ENDPOINT + '/storage/' + response.data.profile_picture}')`;
                });
            }
            if (response.data.cover_photo) {
                this.$nextTick(() => {
                    hero.$refs.heroBackgroundImage.style.backgroundImage = `url('${process.env.API_ENDPOINT + '/storage/' + response.data.cover_photo}')`;
                });
            }
            about.$refs.aboutProfileImage.style.backgroundImage = profile.$refs.headerProfileImage.style.backgroundImage;

            profile.name = response.data.name;
            profile.social_links = response.data.social_links.map(link => {
                return {
                    icon: `fab fa-${link.name}`,
                    ...link
                };
            });

            hero.name = response.data.name;

            about.title = response.data.title;
            about.dob = response.data.dob;
            about.phone = response.data.phone;
            about.email = response.data.email;
            about.location = response.data.location;
            about.about = response.data.about;

            skills.skills = response.data.skills;

            resume.summary.name = response.data.name;
            resume.summary.about = response.data.about;
            resume.summary.location = response.data.location;
            resume.summary.phone = response.data.phone;
            resume.summary.email = response.data.email;
            resume.educations = response.data.education.sort(function (edu_a, edu_b) {
                return new Date(edu_b.from) - new Date(edu_a.from);
            });
            resume.experiences = response.data.experiences.sort(function (exp_a, exp_b) {
                return new Date(exp_b.from) - new Date(exp_a.from);
            });

            testimonials.testimonials = response.data.testimonials;

            contact.location = response.data.location;
            contact.uuid = response.data.uuid;
            contact.email = response.data.email;
            contact.phone = response.data.phone;

            portfolio.portfolio = response.data.portfolios.map(port => {
                port.image = process.env.API_ENDPOINT + '/storage/' + port.image;
                return port;
            });

            services.services = response.data.services;

            document.querySelector('#preloader').remove();
        });
    }
});
